import { useMemo } from 'react';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { filterDraftCategory, getSimpleMetadata } from 'store/assets/selectors';
import type { NotificationSchedule } from 'store/schedules/types';
import { isGeoblocked, getAccess, hasFreeAccess } from 'models/asset';
import { getMultiPreviewsMetadata } from 'components/video/VideoPoster/CustomPreview/utils';
import { tryJSON } from 'lib/string';
import type { FormValues } from '../types';

const getProtections = (asset: Asset): FormValues['protections'] => {
    return {
        geoRestriction: isGeoblocked(asset),
        accessLevel: hasFreeAccess(asset) ? 'free' : getAccess(asset)?.[0] || null,
    };
};

const getPushNotificationsTime = (
    notificationSchedule: NotificationSchedule,
    assetSchedulePublishTime: number | undefined
) => {
    if (notificationSchedule?.time && assetSchedulePublishTime) {
        const diff = Math.abs(notificationSchedule.time - assetSchedulePublishTime);
        // if push time equals scheduled publish time (+/- 60s delay and 10s safety guard)
        // set time to null which means that push will be sent at publish time
        if (diff <= 70) {
            return null;
        }
    }
    return notificationSchedule?.time;
};

export const useFormValues = ({
    asset,
    assetSchedulePublishTime,
    notificationSchedule,
    sendToPodme,
}: {
    asset: Asset;
    assetSchedulePublishTime: number | undefined;
    notificationSchedule: NotificationSchedule;
    sendToPodme: boolean | undefined;
}): FormValues => {
    const formValues = useMemo(
        () => ({
            title: asset.title,
            titleFront: asset.titleFront,
            description: asset.description,
            descriptionFront: asset.descriptionFront,
            articleUrl: asset.articleUrl,
            playback: asset.playback,
            flightTimes: asset.flightTimes,
            schedulePublishTime: assetSchedulePublishTime,
            category: { id: filterDraftCategory(asset.provider, asset.category.id) },
            additional: {
                tags: asset.additional.tags,
                stories: asset.additional.stories,
                subtitles: asset.additional.subtitles,
                cuePoints: asset.additional.cuePoints,
                settings: {
                    source: asset.additional.settings.source,
                    showAds: asset.additional.settings.showAds,
                    showInNewest: asset.additional.settings.showInNewest,
                    notes: asset.additional.settings.notes,
                    ageLimit: asset.additional.settings.ageLimit,
                },
                chapters: asset.additional.chapters,
                metadata: {
                    simpleMetadata: getSimpleMetadata(asset.additional.metadata),
                    multiPreviewsMetadata: getMultiPreviewsMetadata(asset),
                    isPodcast: asset.additional.metadata.isPodcast === 'true',
                    distributeAfter: asset.additional.metadata.distributeAfter || null,
                    podcast_guid: asset.additional.metadata.podcast_guid || '',
                    podcast_episodeType: asset.additional.metadata.podcast_episodeType,
                    tts_disabled: asset.additional.metadata.tts_disabled === 'true',
                    contentType: asset.additional.metadata.contentType || null,
                    mood: asset.additional.metadata.mood,
                    silentplay: asset.additional.metadata.silentplay === 'true',
                    newsLifetime: asset.additional.metadata.newsLifetime || '2',
                    newsValue: asset.additional.metadata.newsValue || '3',
                    studioStartTime: asset.additional.metadata.studioStartTime,
                    eventStartTime: asset.additional.metadata.eventStartTime,
                    richDescription: asset.additional.metadata.richDescription || null,
                    disableNextVideo: asset.additional.metadata.disableNextVideo === 'true',
                    bylineName: asset.additional.metadata.bylineName || '',
                    bylineEmail: asset.additional.metadata.bylineEmail || '',
                    livingThumbnail: asset.additional.metadata.livingThumbnail === 'true',
                    seoTitle: asset.additional.metadata.seoTitle || '',
                    seoDescription: asset.additional.metadata.seoDescription || '',
                    titleShortPath: asset.additional.metadata.titleShortPath || '',
                    authors: asset.additional.metadata.authors || '',
                    movie_cast: tryJSON(asset.additional.metadata.movie_cast) || [],
                    movie_director: asset.additional.metadata.movie_director || '',
                    movie_releasedAt: asset.additional.metadata.movie_releasedAt || '',
                },
            },
            series: {
                seasonNumber: asset.series && asset.series.seasonNumber,
                episodeNumber: asset.series && asset.series.episodeNumber,
            },
            streamUrls: { hls: asset.streamUrls.hls },
            protections: getProtections(asset),
            sendToPodme: sendToPodme || false,
            images: {
                main: asset.images.main,
                featured: asset.images.featured,
                front: asset.images.front,
            },
            pushNotifications: {
                send: Boolean(notificationSchedule),
                content: notificationSchedule?.notificationBody || notificationSchedule?.notificationData?.push?.body,
                time: getPushNotificationsTime(notificationSchedule, assetSchedulePublishTime),
            },
        }),
        [asset, assetSchedulePublishTime, notificationSchedule, sendToPodme]
    );
    return formValues;
};
