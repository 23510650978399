import { pick } from 'lodash';
import { getNewsroomConfig } from 'config';
import { getTimeInSeconds } from 'lib/time';
import { isGeoblocked, getScheduledLiveDuration, getAccess } from 'models/asset';
import { LiveStreamCustomDVR, LiveStreamProtection } from 'services/admin-bff/contants';

/**
 * @param {Object} values
 * @returns {Object}
 */
function getAdvancedSettings(values) {
    const {
        streamQuality,
        dvrLength,
        access,
        profile,
        useBackupEncoder,
        livePreview,
        vertical,
        overlay,
        deinterlace,
        audioNormalization,
    } = values;

    const data = {};

    if (streamQuality === 'standard') {
        data.dvrLength = access?.paywall ? LiveStreamCustomDVR.ENCRYPTED_STANDARD : LiveStreamCustomDVR.STANDARD;
    } else if (streamQuality === 'normal') {
        data.dvrLength = dvrLength;
    }

    if (profile) {
        const profileKeys = Object.keys(profile);
        data.profile = profileKeys.length > 0 && !!profile[profileKeys[0]] ? profileKeys[0] : undefined;
    }

    const { geoblock, paywall } = access || {};

    if (geoblock || paywall) {
        data.protections = [];

        if (geoblock) {
            data.protections.push(LiveStreamProtection.GEOBLOCKED);
        }

        if (paywall && !useBackupEncoder) {
            data.protections.push(LiveStreamProtection.TOKEN_SECURED, LiveStreamProtection.ENCRYPTED);
            data.access = paywall;
        }
    }

    if (livePreview) {
        data.livePreview = true;
    }
    if (vertical) {
        data.vertical = true;
    }
    if (overlay) {
        data.overlay = overlay;
    }
    if (deinterlace) {
        data.deinterlace = true;
    }
    if (audioNormalization) {
        data.audioNormalization = true;
    }

    return data;
}

/**
 * @param {String} newsroom
 * @param {number|undefined} [startTime] - in seconds
 * @return {number|undefined} - in seconds
 */
export function getAdjustedEncoderStartTime(newsroom, startTime) {
    const currentTime = getTimeInSeconds();
    if (!startTime) {
        return currentTime;
    }

    const { encoderStartTimeAhead } = getNewsroomConfig(newsroom);
    const startTimeDiff = startTime - currentTime;
    const startTimeAheadDiff = startTimeDiff - encoderStartTimeAhead;
    const startTimeAheadThreshold =
        startTimeAheadDiff >= 0 ? encoderStartTimeAhead : encoderStartTimeAhead - Math.abs(startTimeAheadDiff);

    return startTime - startTimeAheadThreshold;
}

/**
 * @param {String} newsroom
 * @param {Object} values
 * @param {Boolean} [values.enableAssetSchedule]
 * @param {Boolean} [values.enableEncoderSchedule]
 * @param {Number} [values.encoderDuration]
 * @param {Number} [values.encoderStartTime]
 * @param {Number} [values.startTime]
 * @returns {Object}
 */
export function getScheduleSettings(newsroom, values) {
    const { enableAssetSchedule, enableEncoderSchedule, startTime, encoderStartTime, encoderDuration } = values;

    return {
        ...(enableAssetSchedule && {
            startTime,
            encoderStartTime: enableEncoderSchedule
                ? encoderStartTime || getTimeInSeconds()
                : getAdjustedEncoderStartTime(newsroom, startTime),
        }),
        ...(encoderDuration && { encoderDuration }),
    };
}

/**
 * @param {Object} values
 * @returns {Object}
 */
function getExternalStreamValues(values) {
    return pick(values, 'externalUrl');
}

/**
 * @param {Object} values
 * @returns {Object}
 */
function getRtmpValues(values) {
    return pick(values, 'type', 'streamKey', 'streamType');
}

/**
 * @param {String} newsroom
 * @param {Object} values
 * @returns {Object}
 */
function getMediaLiveInputValues(newsroom, values) {
    return {
        ...getAdvancedSettings(values),
        ...getScheduleSettings(newsroom, values),
        ...pick(values, [
            'inputType',
            'useBackupEncoder',
            'pull-source',
            'audioChannelsMix',
            'inputId',
            'cuttingroomChannel',
        ]),
    };
}

/**
 * @param {String} newsroom
 * @param {Object} values
 * @returns {Object}
 */
function getEncoderInputValues(newsroom, values) {
    const { source, backup, useBackupEncoder, networkUrl, backupNetworkUrl, audioChannelsMix, cuttingroomChannel } =
        values;
    const data = {
        ...getAdvancedSettings(values),
        ...getScheduleSettings(newsroom, values),
    };

    if (source) {
        const { encoderId, inputId } = source;

        data.encoderId = encoderId;
        data.inputId = inputId;
        data.networkUrl = networkUrl;
    }
    if (!useBackupEncoder) {
        data.backup = null;
    }

    if (useBackupEncoder && backup) {
        const { encoderId, inputId } = backup;
        data.backupEncoderId = encoderId;
        data.backupInputId = inputId;
        data.backupNetworkUrl = backupNetworkUrl;
    }

    data.audioChannelsMix = audioChannelsMix;

    data.cuttingroomChannel = cuttingroomChannel;

    return data;
}

/**
 * @param {String} newsroom
 * @param {Object} values
 * @returns {Object}
 */
export function normalizeLiveStreamValues(newsroom, values) {
    if (values.type === 'RTMP') {
        return getRtmpValues(values);
    }

    if (values.inputType) {
        return getMediaLiveInputValues(newsroom, values);
    }

    if (values.externalUrl) {
        return getExternalStreamValues(values);
    }

    return getEncoderInputValues(newsroom, values);
}

/**
 * @param {Object} asset
 * @param {Object[]} events
 * @returns {Object}
 *
 * Fields like `enableEncoderSchedule` and `encoderStartTime` should be skipped as there's no event/channel available
 */
export function formatLiveStreamValues(asset, events) {
    const values = {};

    if (window.__ADMIN_ENV__ !== 'production') {
        values.encoderDuration = 3600;
    }

    if (!asset) {
        return values;
    }
    if (events && events.length) {
        if (events[0].dvrLength && events[0].dvrLength !== 21600) {
            values.dvrLength = events[0].dvrLength;
            values.streamQuality = 'normal';
        }
        if (events[0]?.hasLivePreview) {
            values.livePreview = true;
        }
    }

    const currentTime = getTimeInSeconds();
    const { start: startTime } = asset.flightTimes;
    const liveDuration = getScheduledLiveDuration(asset);

    if (startTime > 0 && startTime >= currentTime) {
        values.startTime = startTime;
        values.enableAssetSchedule = true;
    } else {
        values.enableAssetSchedule = false;
    }

    if (liveDuration) {
        values.encoderDuration = liveDuration;
    }

    const assetAccessEntries = getAccess(asset);
    values.access = {
        geoblock: isGeoblocked(asset),
        paywall: assetAccessEntries?.[0] ?? undefined,
    };

    return values;
}
