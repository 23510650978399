const config = {
    api: {
        host: 'https://cms-api.stream.schibsted.media',
    },
    apiProxy: {
        host: 'https://svp-api-proxy-production.k8s.linode.stream.schibsted.media',
        path: '/api',
    },
    adminBff: {
        host: 'https://cms-bff.stream.schibsted.media',
    },
    svpApi: {
        host: 'https://svp.vg.no',
    },
    playlistsApi: {
        host: 'https://playlist.stream.schibsted.media',
    },
    mqtt: {
        urlVg: 'wss://mqtt.stream.schibsted.media',
        urlLinode: 'wss://mqtt.k8s.linode.stream.schibsted.media',
    },
    player: {
        options: {
            api: 'https://svp.vg.no/svp/api/v1/',
            tokenUrl: 'https://svp.vg.no/svp/token/v1/',
            thumbnailsUrl: 'https://svp.vg.no/svp/thumbnails/v1/',
        },
    },
    embed: {
        scriptUrl: 'https://cdn.stream.schibsted.media/embed/latest/js/embed.min.js',
        iframeUrl: 'https://cdn.stream.schibsted.media/embed/latest/iframe.html',
    },
    podcastsApi: {
        url: 'https://podcast.stream.schibsted.media',
    },
    relatedApi: {
        url: 'https://related.stream.schibsted.media',
    },
    linode: {
        bucket: 'stream-ingest-prod',
    },
    aws: {
        key: '89TRI7E3WQSP5TYR50PZ',
        bucket: 'svp-ingest',
    },
    ga: {
        trackingCode: 'UA-129902176-1',
    },
    sentry: {
        environment: 'prod',
    },
    asset: {
        defaultCategory: {
            ab: 1113,
            vgtv: 100312,
            adressa: 600125,
            altaposten: 34,
            folkebladet: 16,
            harstad: 4,
            itromso: 9,
            rbnett: 17,
            smp: 43,
            tronderavisa: 21,
        },
        hiddenCategory: {
            vgtv: 100312,
            ab: 1113,
            adressa: 600125,
            altaposten: 34,
            folkebladet: 16,
            harstad: 4,
            itromso: 9,
            rbnett: 17,
            smp: 43,
            tronderavisa: 21,
        },
    },
    podcasts: {
        categoryId: {
            e24: 100442,
        },
    },
    live: {
        rtmp: {
            url: 'rtmp://rtmp.stream.schibsted.media/live',
        },
    },
} as const;

export default config;
