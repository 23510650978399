import type { Control, UseFormRegister, UseFieldArrayReturn } from 'react-hook-form';
import { MdAdd } from 'react-icons/md';
import { LabeledContainer, Input, Button } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { useUserRoles } from 'hooks/usersManagement/useUserRoles';
import { useDynamicFields } from '../hooks/useDynamicFields';
import type { FormValues } from '../types';
import css from './AssetMetadataInputs.module.scss';

// TODO: ADD VALIDATE AND TRIM BELLOW
// const validateKey = (value) => (value && value.trim() ? undefined : 'Metadata key is required');
// const trimKey = (value) => value.trim();

const disabledFields = ['aspectRatio'];

type AssetMetadataInputsProps = {
    asset: Asset;
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
};
export function AssetMetadataInputs({ asset, control, register, simpleMetadataControls }: AssetMetadataInputsProps) {
    const { fields, remove, append } = simpleMetadataControls;
    const { isDynamicField } = useDynamicFields({ asset, control });

    const roles = useUserRoles();
    const isAdmin = roles.find(({ roleType }) => roleType === 'admin');

    const isDisabled = (field) => disabledFields.find((disabledField) => disabledField === field?.key) && !isAdmin;

    return (
        <LabeledContainer label="Add new properties">
            <div className={css.container}>
                {fields.map((field, index) => {
                    const disabled = isDisabled(field);
                    return (
                        !isDynamicField(field.key) && (
                            <div key={field.id} className={css.inputsRow}>
                                <Input
                                    size="small"
                                    {...register(`additional.metadata.simpleMetadata.${index}.key`)}
                                    disabled={disabled}
                                />
                                <Input
                                    size="small"
                                    disabled={disabled}
                                    {...register(`additional.metadata.simpleMetadata.${index}.value`)}
                                />
                                <Button
                                    size="small"
                                    variant="standard"
                                    type="button"
                                    onClick={() => remove(index)}
                                    disabled={disabled}
                                >
                                    Delete
                                </Button>
                            </div>
                        )
                    );
                })}
                <Button
                    type="button"
                    size="small"
                    className={css.addButton}
                    iconOnly
                    onClick={() => append({ key: '', value: '' })}
                >
                    <MdAdd />
                </Button>
            </div>
        </LabeledContainer>
    );
}
