import PropTypes from 'prop-types';
import config from 'config';
import { CopyToClipboard, Label } from '@schibsted-svp/react-ui';
import css from './PodcastRssUrl.module.scss';

function PodcastRssUrl({ categoryId, provider }) {
    const url = `${config.podcastsApi.url}/${provider}/${categoryId}`;

    return (
        <div className={css.container}>
            <Label className={css.label}>Podcast RSS URL</Label>
            <CopyToClipboard
                className={css.urlContainer}
                text={url}
                title="Clip to copy podcast RSS URL"
                onCopyMessage="Podcast RSS URL copied to clipboard"
            />
        </div>
    );
}

PodcastRssUrl.propTypes = {
    categoryId: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
};

export default PodcastRssUrl;
